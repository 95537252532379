import axiosInstance from './Api';

export default {

    Login(payload) {
        const { email, password } = payload;
        return axiosInstance().post(`/login`, { email, password });
    },

    Register(payload) {
        return axiosInstance().post(`/register`, payload);
    },

    SendPasswordResetLink(payload) {
        return axiosInstance().post(`/send-password-reset-link`, payload);
    },

    ResetPassword(payload) {
        return axiosInstance().post(`/reset-password`, payload);
    },

}